import { CAMPUS_LINKS } from '@lib/data/all-courses-navbar'
import { Trans } from '@lingui/macro'
import { useEffect, useState } from 'react'
import { ChevronDown } from 'react-feather'

type CampsDropDownProps = {
  isDark?: boolean
}

const CampsDropdown = ({ isDark = false }: CampsDropDownProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  return (
    <div
      className="relative flex lg:items-center flex-col lg:flex-row"
      onClick={() => setIsOpen(!isOpen)}
      onMouseEnter={
        isClient && window.innerWidth >= 1024
          ? () => setIsOpen(true)
          : undefined
      }
      onMouseLeave={
        isClient && window.innerWidth >= 1024
          ? () => setIsOpen(false)
          : undefined
      }
    >
      <button
        type="button"
        className="flex items-center group focus:outline-none cursor-auto"
      >
        <span
          className={`${
            isDark ? 'text-white' : 'text-grey group-hover:text-orange'
          } font-500 md:font-600 text-xl md:text-lg`}
        >
          <Trans>Camps</Trans>
        </span>
        <ChevronDown
          size={16}
          className={`ml-1 ${
            isDark ? 'text-white' : 'text-grey group-hover:text-orange'
          }`}
        />
      </button>
      <div
        className={`ml-4 lg:ml-0 lg:mt-10 lg:absolute top-0 left-0 bg-white rounded-lg lg:shadow-xl lg:space-y-3 overflow-hidden ${
          isOpen
            ? 'w-full lg:w-64 lg:p-5 md:border md:border-grey-300 z-50'
            : 'w-0 h-0'
        }`}
      >
        {CAMPUS_LINKS.map(({ title, link }) => (
          <div key={title?.id}>
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center space-x-5 lg:border-b group border-grey-300 pb-1 py-2 lg:py-0"
            >
              <span className="text-lg inline-block text-grey font-500 lg:font-600 group-hover:text-orange lg:text-base">
                <Trans id={title?.id} />
              </span>
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CampsDropdown
